"use client";

import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import CloseButton from "@components/Common/Modals/CloseButton";

const ModalContainer = ({
  isOpen,
  useTimeout = false,
  hideCloseButton = false,
  timeout = 1000,
  closeModal,
  children
}: {
  isOpen: boolean;
  useTimeout?: boolean;
  hideCloseButton?: boolean;
  timeout?: number;
  closeModal?: () => void;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    if (isOpen && useTimeout) {
      setTimeout(() => {
        closeModal && closeModal();
      }, timeout);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={isOpen ? "ModalBackgroundOpen" : "ModalBackground"} onClick={() => closeModal && closeModal()}>
      <div className={isOpen ? "ModalOpen" : "Modal"} onClick={(e) => e.stopPropagation()}>
        {!hideCloseButton && <CloseButton onClick={() => closeModal && closeModal()} />}
        {children}
      </div>
    </div>,
    document.body
  );
};

export default ModalContainer;
