"use client";

import styles from "@components/Common/Modals/AddedToCart/AddToCart.module.scss";
import SVG from "react-inlinesvg";
import Heading from "@components/Common/Text/Heading";
import React, { useEffect } from "react";

const ErrorMessage = ({ errorMessage, onTimeout }: { errorMessage: string; onTimeout?: () => void }) => {
  useEffect(() => {
    setTimeout(() => {
      onTimeout && onTimeout();
    }, 5000);
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${styles.header_error}`}>
        <SVG src="/images/icons/close.svg" />
      </div>
      <div className={`${styles.content} ${styles.content_error}`}>
        <Heading padding={false}>{errorMessage}</Heading>
      </div>
    </div>
  );
};

export default ErrorMessage;
