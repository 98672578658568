"use client";

import styles from "./AddToCart.module.scss";
import Heading from "@components/Common/Text/Heading";
import Span from "@components/Common/Text/Span";
import React, { useContext } from "react";
import { CartItem } from "@utils/Cart/CartItem";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";

const AddedToCart = ({ cartItem }: { cartItem: CartItem }) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div className="ModalContainer">
      <div className={styles.content}>
        <Heading padding={false}>{dictionary.addedToCart}</Heading>

        <Heading color="color-13" level={5} className="pt-4 !pb-0">
          {cartItem.product
            ? cartItem.product?.productNameShort
            : cartItem.configuration
            ? cartItem.configuration.name
            : ""}
        </Heading>
        <Span color="color-13" size="xs">
          {cartItem.product && cartItem.product?.productNumber}
        </Span>
      </div>
    </div>
  );
};

export default AddedToCart;
