import styles from "./RequestForm.module.scss";
import { CartItem } from "@utils/Cart/CartItem";
import React, { useContext, useState } from "react";
import { Step } from "@constants/interfaces";
import Heading from "@components/Common/Text/Heading";
import Span from "@components/Common/Text/Span";
import QuantitySelector from "@components/Input/QuantitySelector";
import TextInput from "@components/Input/TextInput";
import { EmailHandlerImpl, ProductRequest, ResponseStatus } from "@utils/email/EmailHandler";
import { LocaleCode } from "@utils/Country/countryEnums";
import * as Sentry from "@sentry/nextjs";
import { getClientLocale } from "@utils/Localization/getClientLocale";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { ErrorTypes } from "@utils/Validation/getValidationMessage";
import Button from "@components/Common/Button";

export const RequestForm = ({ cartItem, closeModal }: { cartItem: CartItem; closeModal: () => void }) => {
  const { dictionary } = useContext(LocalizationContext);
  const locale = getClientLocale();

  const [quantity] = useState<number>(cartItem.quantity || 1);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const [data, setData] = useState<any>({
    name: "",
    company: "",
    email: "",
    message: "",
    product: cartItem.product,
    configuration: cartItem.configuration,
    quantity: quantity
  });

  const handleChange = (key: keyof typeof data, value: any) => {
    setData({ ...data, [key]: value });
  };

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!data.name || !data.email || !data.company || !data.message) {
      return;
    }

    const emailHandler = new EmailHandlerImpl();

    const emailData: ProductRequest = {
      data: {
        name: data.name,
        email: data.email,
        message: data.message,
        company: data.company,
        quantity: data.quantity,
        product: cartItem.product
          ? {
              productNumber: cartItem.product.productNumber,
              elNumber: cartItem.product.elNumber,
              productName: cartItem.product.productName,
              productNameShort: cartItem.product.productNameShort,
              category: cartItem.product.product_category?.name,
              quantity: cartItem.quantity
            }
          : undefined,
        configuration: cartItem.configuration
          ? {
              productName: cartItem.configuration.name,
              selections: cartItem.configuration.selections.map((selection: Step) => {
                return {
                  productNumber: selection.product.productNumber,
                  elNumber: cartItem.product?.elNumber,
                  productName: selection.product.productName,
                  productNameShort: cartItem.product?.productNameShort,
                  quantity: cartItem.quantity
                };
              })
            }
          : undefined
      },
      locale: locale || LocaleCode.Norway
    };

    try {
      setLoader(true);

      const externalResult = await emailHandler.sendProductRequestExternal(emailData);

      if (externalResult.status !== ResponseStatus.Success) {
        setError(true);
        setLoader(false);
        closeModal();

        return;
      }

      const internalResult = await emailHandler.sendProductRequestInternal(emailData);

      if (internalResult.status !== ResponseStatus.Success) {
        setError(true);
        setLoader(false);
        closeModal();

        return;
      }

      setData({
        name: "",
        company: "",
        email: "",
        message: "",
        quantity: 1
      });

      setLoader(false);
      setSent(true);

      closeModal();
    } catch (e) {
      Sentry.captureException(e);
      setLoader(false);
      setSent(false);
      setError(true);
      closeModal();
    }
  };

  // todo: remove tailwind classes

  return (
    <div className="ModalContainer">
      <div className={styles.container}>
        <div className={styles.heading}>
          <>
            <Heading>{dictionary.submitRequest}</Heading>
            <div className="flex flex-row justify-between items-center">
              <Span weight="bold">
                {dictionary.product} - {cartItem.product?.productNameShort || cartItem.configuration?.name}
              </Span>
              <QuantitySelector />
            </div>
          </>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <TextInput
            name={dictionary.name}
            onChange={(value) => handleChange("name", value)}
            value={data.name}
            validation={{
              type: ErrorTypes.Name,
              message: dictionary.invalidName
            }}
            label={dictionary.name}
            required
          />
          <TextInput
            name="email"
            onChange={(value) => handleChange("email", value)}
            value={data.email}
            label={dictionary.email}
            validation={{
              type: ErrorTypes.Email,
              message: dictionary.invalidEmail
            }}
            required
          />
          <TextInput
            name={dictionary.company}
            onChange={(value) => handleChange("company", value)}
            value={data.company}
            label={dictionary.company}
            validation={{
              type: ErrorTypes.Company,
              message: dictionary.invalidCompany
            }}
            required
          />
          <TextInput
            type={"textArea"}
            name={dictionary.message}
            onChange={(value) => handleChange("message", value)}
            value={data.message}
            label={dictionary.message}
            required
          />

          <Button type={loader ? "Button" : "Submit"} color="Light" backgroundColor="Dark" width="fit-content">
            <span>
              {!error
                ? !sent
                  ? (!loader && dictionary.send) || dictionary.sending
                  : dictionary.messageSent
                : dictionary.unknownError}
            </span>
          </Button>
        </form>
      </div>
    </div>
  );
};
