"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "@components/Auth/UserProvider";
import { CartContext } from "@utils/Cart/cartProvider";
import { CartItem, CartItemImpl } from "@utils/Cart/CartItem";
import { ConfigurationInterface } from "@constants/interfaces";
import { ProductData } from "@utils/Product/interfaces";

export interface CartItemInterface {
  cartItem: CartItem | null;
  setQuantityState?: (quantity: number) => void;
}

export const CartItemContext = createContext<CartItemInterface>({} as CartItemInterface);

const CartItemProvider = ({
  children,
  directCartItem,
  productData,
  configuration,
  quantity = 1
}: {
  children: React.ReactNode | React.ReactNode[];
  directCartItem?: CartItem; // If we want to use an existing cart item from a cart
  productData?: ProductData;
  configuration?: ConfigurationInterface;
  quantity?: number;
}) => {
  const { user } = useContext(UserContext);
  const { discountCode, setCartItem } = useContext(CartContext);

  const [quantityState, setQuantityState] = useState(quantity);
  const [localCartItem, setLocalCartItem] = useState<CartItem | null>(null);

  useEffect(() => {
    if (directCartItem) {
      directCartItem.setQuantity(quantityState);
      setCartItem(directCartItem);
      return;
    }

    const newCartItem = new CartItemImpl({
      product: productData,
      configuration,
      quantity: quantityState,
      priceLevel: user?.priceLevel,
      discountCode
    });

    setLocalCartItem(newCartItem);
  }, [user, discountCode, configuration, productData, quantityState]);

  return (
    <CartItemContext.Provider
      value={{
        cartItem: directCartItem ? directCartItem : localCartItem,
        setQuantityState
      }}
    >
      {children}
    </CartItemContext.Provider>
  );
};

export default CartItemProvider;
