"use client";

import { PriceInterface } from "@constants/interfaces";
import { useContext } from "react";
import { CartItem } from "@utils/Cart/CartItem";
import { CartContext } from "@utils/Cart/cartProvider";
import { useCountryContext } from "@utils/Country/useCountryContext";
import { CurrencySide } from "@utils/Country/countryEnums";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";

// TODO remove discount when logic moved to product class
const FormatPrice = ({
  priceObject,
  cartItem,
  useTotals,
  discountPercentage
}: {
  priceObject?: PriceInterface | undefined;
  cartItem?: CartItem;
  useTotals?: boolean;
  discountPercentage?: number;
}) => {
  const { dictionary } = useContext(LocalizationContext);
  const { countryManager } = useCountryContext();
  const { discountCode } = useContext(CartContext);

  const insertDecimal = (num: number) => {
    return num?.toFixed(2);
  };

  let newPriceObject: PriceInterface | undefined = priceObject;

  if (cartItem && !useTotals && !discountCode?.enabled) {
    newPriceObject = cartItem.getPrice();
  }

  if (cartItem && useTotals && !discountCode?.enabled) {
    newPriceObject = cartItem.getTotal();
  }

  if (cartItem && !useTotals && discountCode?.enabled) {
    newPriceObject = cartItem.getDiscountedPrice();
  }

  if (cartItem && useTotals && discountCode?.enabled) {
    newPriceObject = cartItem.getDiscountedTotal();
  }

  if (newPriceObject == undefined || isNaN(newPriceObject.price)) {
    return null;
    //    return <>{dictionary.submitEnquiry}</>;
  }

  if (!newPriceObject.level) {
    return null;
  }

  let formattedPrice: string;

  if (newPriceObject.price === 0) return <>{dictionary.submitEnquiry}</>;

  const price = newPriceObject.price - (newPriceObject.price * (discountPercentage || 0)) / 100;
  const countryInfo = countryManager.getCountryByCurrency(newPriceObject.currency);
  formattedPrice = insertDecimal(price);

  if (countryInfo?.currencyPlacement && countryInfo.currencyPlacement === CurrencySide.Start) {
    formattedPrice = `${countryInfo.currencySymbol}${insertDecimal(price)}`;
  }
  if (countryInfo?.currencyPlacement && countryInfo.currencyPlacement === CurrencySide.End) {
    formattedPrice = `${insertDecimal(price)}${countryInfo.currencySymbol} `;
  }

  return <>{formattedPrice}</>;
};

export default FormatPrice;
