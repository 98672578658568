import styles from "./Button.module.scss";
import React from "react";

export enum ButtonType {
  Button = "button",
  Submit = "submit",
  Reset = "reset"
}

export enum ButtonDesign {
  Dark = "dark",
  Light = "light",
  Gray = "gray",
  Transparent = "transparent",
  Outline = "outline"
}

export enum ButtonColor {
  White = "white",
  Light = "light",
  Dark = "dark",
  Gray = "gray"
}

const designStyleMap: Record<keyof typeof ButtonDesign, string> = {
  Dark: styles.dark,
  Light: styles.light,
  Gray: styles.gray,
  Transparent: styles.transparent,
  Outline: styles.outline
};

const borderStyleMap: Record<keyof typeof ButtonColor, string> = {
  White: styles.borderWhite,
  Light: styles.borderLight,
  Dark: styles.borderDark,
  Gray: styles.borderGray
};

const colorStyleMap: Record<keyof typeof ButtonColor, string> = {
  White: styles.colorWhite,
  Light: styles.colorLight,
  Dark: styles.colorDark,
  Gray: styles.colorGray
};

const backgroundColorStyleMap: Record<keyof typeof ButtonColor, string> = {
  White: styles.bgWhite,
  Light: styles.bgLight,
  Dark: styles.bgDark,
  Gray: styles.bgGray
};

const getStyleFromMap = <T extends NonNullable<unknown>>(map: Record<keyof T, string>, value?: keyof T) => {
  return value ? map[value] || "" : "";
};

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  type?: keyof typeof ButtonType;
  design?: keyof typeof ButtonDesign;
  border?: keyof typeof ButtonColor;
  color?: keyof typeof ButtonColor;
  backgroundColor?: keyof typeof ButtonColor;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  children,
  className = "",
  width,
  height = "53px",
  fontSize = "1.2rem",
  fontWeight = "300",
  type = "Button",
  design = "Dark",
  border,
  color,
  backgroundColor,
  icon,
  onClick
}: ButtonProps) => {
  return (
    <button
      type={ButtonType[type]}
      onClick={onClick}
      className={[
        className,
        getStyleFromMap(designStyleMap, design),
        getStyleFromMap(colorStyleMap, color),
        getStyleFromMap(backgroundColorStyleMap, backgroundColor),
        getStyleFromMap(borderStyleMap, border)
      ].join(" ")}
      style={{ width, height, lineHeight: height, fontSize, fontWeight }}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <span>{children}</span>
    </button>
  );
};

export default Button;
