import React from "react";

interface SpanInterface {
  children: React.ReactNode;
  size?: "xs" | "sm" | "md" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl";
  weight?: "thin" | "extralight" | "light" | "normal" | "medium" | "semibold" | "bold" | "extrabold" | "black";
  color?: string;
  leading?: number;
  className?: string;
  transform?: "uppercase" | "lowercase" | "capitalize" | boolean;
  style?: React.CSSProperties;
}

const Span = ({
  children,
  size = "base",
  weight = "light",
  leading = 7,
  color = "color-gray",
  className = "",
  transform = false,

  style
}: SpanInterface) => {
  const classNameString = `leading-${leading} ${color} ${size && `text-${size}`} font-${weight} ${className} ${
    (transform && `${transform} first-letter:${transform}`) || ""
  } `;

  if (typeof children !== "string") return <span className={classNameString}>{children}</span>;

  let text = children;

  if (!transform) {
    const firstLetter = children[0];
    const restOfText = children.slice(1);

    text = firstLetter && firstLetter.toUpperCase() + restOfText;
  }

  return (
    <span className={classNameString} style={style}>
      {text}
    </span>
  );
};

export default Span;
