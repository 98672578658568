"use client";

import React, { useContext, useState } from "react";
import { RequestForm } from "@components/Products/RequestForm";
import ModalContainer from "@components/Common/Modals/ModalContainer";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { CartItemContext } from "@components/Cart/CartItem/CartItemProvider";
import { ShoppingCart } from "phosphor-react";
import Button from "@components/Common/Button";

export const SubmitProductRequestButton = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { cartItem } = useContext(CartItemContext);
  const [modal, setModal] = useState(false);

  if (!cartItem) {
    return null;
  }

  const handleClick = () => {
    setModal(true);
  };

  return (
    <div>
      <ModalContainer isOpen={modal} closeModal={() => setModal(false)}>
        <RequestForm cartItem={cartItem} closeModal={() => setModal(false)} />
      </ModalContainer>

      <Button
        type="Button"
        design="Dark"
        height="3rem"
        fontSize="1.3rem"
        icon={<ShoppingCart size={20} />}
        onClick={handleClick}
      >
        {dictionary.submitRequest}
      </Button>
    </div>
  );
};

export default SubmitProductRequestButton;
