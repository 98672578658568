"use client";

import SubmitProductRequestButton from "@components/Common/SubmitProductRequestButton";
import AddToCartButton from "@components/Common/AddToCartButton";
import React, { useContext } from "react";
import { CartItemContext } from "@components/Cart/CartItem/CartItemProvider";

const SubmitOrAddToCartButton = () => {
  const { cartItem } = useContext(CartItemContext);

  if (!cartItem) {
    return null;
  }

  if (cartItem.product && cartItem.product.price && cartItem.product.price.length > 0) {
    return <AddToCartButton />;
  }

  if (cartItem.configuration && cartItem.configuration.selections && cartItem.configuration.selections.length > 0) {
    return <AddToCartButton />;
  }

  return <SubmitProductRequestButton />;
};

export default SubmitOrAddToCartButton;
