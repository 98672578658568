import React from "react";

interface HeadingInterface {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  weight?: "thin" | "extralight" | "light" | "normal" | "medium" | "semibold" | "bold" | "extrabold" | "black";
  size?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "8xl" | "9xl" | string;
  color?: string;
  leading?: number;
  className?: string;
  id?: string;
  padding?: string | boolean;
  style?: React.CSSProperties;
}

const Heading = ({
  level = 3,
  children = "",
  weight = "extralight",
  size = "",
  leading = 7,
  color = "color-gray",
  className = "",
  id,
  style,
  padding = "pb-4"
}: HeadingInterface) => {
  const Heading: React.ElementType = `h${level}`;

  if (size?.includes("px")) {
    size = `[${size}]`;
  }

  if (color === "") {
    color = "color-gray";
  }

  const classNameString = `leading-${leading} ${color} ${(size && `text-${size}`) || ""}  font-${weight} ${className} ${
    (padding && "pb-4") || ""
  }`;

  if (typeof children !== "string") return <Heading className={classNameString}>{children}</Heading>;

  const firstLetter = children[0];
  const restOfText = children.slice(1);

  const text = firstLetter && firstLetter.toUpperCase() + restOfText;

  return (
    <Heading
      id={id}
      className={classNameString}
      style={{
        ...style
      }}
    >
      {text}
    </Heading>
  );
};

export default Heading;
