import styles from "./CloseButton.module.scss";

import React from "react";
import { X } from "@phosphor-icons/react";

const CloseButton = ({ onClick }: { onClick: (e: React.MouseEvent<HTMLDivElement>) => void }) => {
  return (
    <div className={styles.closeButton} onClick={(e) => onClick(e)}>
      <X size={16} weight="light" />
    </div>
  );
};

export default CloseButton;
