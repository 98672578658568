"use client";

import React, { useContext } from "react";
import { CartItemContext } from "@components/Cart/CartItem/CartItemProvider";
import { QuantityInput } from "@components/Input/QuantityInput";

const QuantitySelector = ({ className }: { className?: string }) => {
  const { cartItem, setQuantityState } = useContext(CartItemContext);

  if (!cartItem) {
    return null;
  }

  return (
    <QuantityInput
      className={className}
      defaultValue={cartItem.quantity}
      // maxQuantity={cartItem.product?.stock}
      maxQuantity={10000}
      setQuantity={(quantity) => {
        setQuantityState && setQuantityState(quantity);
      }}
    />
  );
};

export default QuantitySelector;
