"use client";

import React, { useContext, useState } from "react";
import { CartContext } from "@utils/Cart/cartProvider";
import * as Sentry from "@sentry/nextjs";
import AddedToCart from "@components/Common/Modals/AddedToCart/AddedToCart";
import ErrorMessage from "@components/Common/Modals/ErrorMessage";
import ModalContainer from "@components/Common/Modals/ModalContainer";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { CartItemContext } from "@components/Cart/CartItem/CartItemProvider";
import Button from "@components/Common/Button";

export const AddToCartButton = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { cartItem } = useContext(CartItemContext);
  const { addToCart } = useContext(CartContext);

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (!cartItem) {
    return null;
  }

  const handleAddToCart = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      addToCart(cartItem);
      setModal(true);
    } catch (e: any) {
      Sentry.captureException(e);

      setErrorMessage(dictionary.errorAddToCart);

      if (e.error?.code === 412) {
        setErrorMessage(dictionary.missingPrice);
      } else {
        setErrorMessage(dictionary.unknownError);
      }

      setErrorModal(true);
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <div>
      <ModalContainer isOpen={modal} closeModal={() => handleClose()} useTimeout>
        <AddedToCart cartItem={cartItem} />
      </ModalContainer>

      <ModalContainer isOpen={errorModal} closeModal={handleClose} useTimeout timeout={5000}>
        <ErrorMessage errorMessage={errorMessage} onTimeout={() => setErrorModal(false)} />
      </ModalContainer>

      <Button type="Button" design="Dark" height="3rem" fontSize="1.2rem" onClick={(e) => handleAddToCart(e)}>
        {dictionary.addToCart}
      </Button>
    </div>
  );
};

export default AddToCartButton;

/*
 keys to replace: {dictionary.addToCart}
 */
