"use client";

import styles from "@components/Input/QuantityInput.module.scss";
import React, { useEffect, useState } from "react";
import TextInput from "@components/Input/TextInput";
import { MinusCircle, PlusCircle } from "@phosphor-icons/react";

export const QuantityInput = ({
  className,
  defaultValue,
  maxQuantity,
  setQuantity
}: {
  className?: string;
  defaultValue: number;
  maxQuantity?: number;
  setQuantity?: (quantity: number) => void;
}) => {
  const [quantity, setQuantityState] = useState(defaultValue);

  useEffect(() => {
    setQuantity && setQuantity(quantity);
  }, [quantity]);

  const handleQuantityChange = (value: string) => {
    if (!value || value === "" || value === "0") {
      return;
    }

    const parsedValue = parseInt(value);
    if (maxQuantity && parsedValue > maxQuantity) {
      setQuantityState(maxQuantity);
    } else {
      setQuantityState(parsedValue);
    }
  };

  const incrementQuantity = () => {
    if (maxQuantity && quantity < maxQuantity) {
      setQuantityState(quantity + 1);
    } else if (!maxQuantity) {
      setQuantityState(quantity + 1);
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantityState(quantity - 1);
    }
  };

  const numDigits = quantity.toString().length;
  const widthValue = 10 * numDigits;

  return (
    <div className={`${className} ${styles.container}`}>
      <MinusCircle size={24} weight="thin" onClick={() => decrementQuantity()} className={styles.decrement} />

      <div
        className={styles.input}
        style={{
          width: `${14 + widthValue}px`
        }}
      >
        <TextInput
          design="None"
          name="number"
          type="number"
          value={quantity}
          min={1}
          onChange={(value) => handleQuantityChange(value)}
        />
      </div>

      <PlusCircle size={24} weight="thin" onClick={() => incrementQuantity()} className={styles.decrement} />
    </div>
  );
};
